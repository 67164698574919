.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: rgb(53,58,63);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.navbar-container-light {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;   
    border-bottom: 1px rgb(202,202,202) solid;
}

.navbar-mark-container {
    display: flex;
    align-items: center;
}

.navbar-bts {
    margin-left: 10px;
    color: white;
}

.navbar-bts-light {
    margin-left: 10px;
    color: black;
}

.navbar-mark {
    width: 200px;
    cursor: pointer;
}

.navbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
}

.navbar-sticky-header {
    position: fixed;
    width: 100%;
}

.navbar-stripe-banner {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    background-color: #39C16C;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    box-shadow: 0px 2px 20px grey;
}

.navbar-stripe-banner-bold {
    font-weight: 700;
}

.navbar-stripe-banner-link {
    text-decoration: underline;
    color: white;
}
.navbar-stripe-banner-link:hover {
    color: white;
}