.sign-up-links {
    display: flex;
    margin-top: 5px;
}

.sign-up-links-separator {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid rgb(202,202,202);
}

.sign-up-links-outer {
    margin-top: 10px;
}